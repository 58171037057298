import React, { useState } from "react"

const defaultState = {
    hcpExitModalShow: false,
    setHcpExitModalShow: () => {},

    emailHcpsModalShow: false,
    setEmailHcpsModalShow: () => {},
}

const AppContext = React.createContext(defaultState)

const AppProvider = ({ children }) => {
    const [hcpExitModalShow, setHcpExitModalShow] = useState(defaultState.hcpExitModalShow)
    const [emailHcpsModalShow, setEmailHcpsModalShow] = useState(defaultState.emailHcpsModalShow)

    const store = {
        hcpExitModalShow: hcpExitModalShow,
        setHcpExitModalShow: setHcpExitModalShow,
        
        emailHcpsModalShow: emailHcpsModalShow,
        setEmailHcpsModalShow: setEmailHcpsModalShow,
    }

    return <AppContext.Provider value={store}>{children}</AppContext.Provider>
}

export default AppContext

export { AppProvider }
